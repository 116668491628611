import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
// Components
import { Seo, EnrollmentForm } from '../components'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'

const EnrollmentFormPage = () => {
  const { userType, isAuthLoading, isAuthenticated } = useAuthContext()
  const {
    prismicData: { prismicGeneral, prismicHomepage },
  } = usePrismic()

  useEffect(() => {
    if (isAuthLoading) return
    if (userType === 'AMBASSADOR') {
      alert(prismicGeneral.msg_it_looks_like_you_are_already_an_ambassador)
      localStorage.removeItem('enrollmentForm')
      navigate('/')
    }
    if (!isAuthenticated && !isAuthLoading) navigate('/login')
  }, [isAuthLoading, isAuthenticated, userType])
  // TODO change prismic page
  return (
    <>
      <Seo title={prismicHomepage.title[0].text} />
      <EnrollmentForm />
    </>
  )
}

export default EnrollmentFormPage
